import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  List,
  ListItem,
  ListItemText,
  Modal,
  Typography,
} from '@mui/material'
import { useContext, useState } from 'react'
import { AuthContext } from '../context/auth'

const Collection = () => {
  const { userData } = useContext(AuthContext)
  const { collection, stats } = userData

  const [open, setOpen] = useState<boolean>(false)
  const [shiny, setShiny] = useState<boolean>(false)

  const allIds = new Array(151).fill(0).map((_, idx) => idx + 1)

  const getNormalContent = () => {
    return (
      <CardContent>
        <Grid container columns={8} textAlign="center" justifyContent="center">
          <Grid item xs={1}></Grid>
          <Grid item xs={6}>
            <Typography
              gutterBottom
              variant="h4"
              component="div"
              textAlign={'center'}
            >
              {`Collection (${collection.length}/151)`}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Button
              startIcon={
                <img
                  src="shiny-charm.png"
                  alt={'Shiny Charm'}
                  height={30}
                  width={30}
                />
              }
              onClick={() => setShiny(true)}
            />
          </Grid>
        </Grid>

        <List sx={{ width: '100%', height: '55vh', overflow: 'auto' }}>
          {allIds.map((id) => (
            <ListItem dense={true} key={id}>
              <ListItemText
                primary={`#${String(id).padStart(3, '0')}`}
                secondary={
                  collection.find((c) => c.id === id)
                    ? `${collection.find((c) => c.id === id)?.name} x${
                        stats.hatches[id].count
                      }`
                    : '???'
                }
              />
            </ListItem>
          ))}
        </List>
      </CardContent>
    )
  }

  const getShinyContent = () => {
    return (
      <CardContent>
        <Grid container columns={8} textAlign="center" justifyContent="center">
          <Grid item xs={1} />
          <Grid item xs={6}>
            <Typography
              gutterBottom
              variant="h4"
              component="div"
              textAlign={'center'}
            >
              {`⭐Shiny⭐ Collection (${
                Object.values(stats.hatches).filter((data) => data.shiny > 0)
                  .length
              }/151) `}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Button
              startIcon={
                <img
                  src="shiny-charm.png"
                  alt={'Shiny Charm'}
                  height={30}
                  width={30}
                />
              }
              onClick={() => setShiny(false)}
            />
          </Grid>
        </Grid>

        <List sx={{ width: '100%', height: '55vh', overflow: 'auto' }}>
          {allIds.map((id) => (
            <ListItem dense={true} key={id}>
              <ListItemText
                primary={`#${String(id).padStart(3, '0')}`}
                secondary={
                  (stats.hatches[id] ? stats.hatches[id].shiny ?? 0 : 0) > 0
                    ? `${collection.find((c) => c.id === id)?.name} x${
                        stats.hatches[id].shiny
                      }`
                    : '???'
                }
              />
            </ListItem>
          ))}
        </List>
      </CardContent>
    )
  }

  return (
    <>
      <Button
        variant="contained"
        startIcon={
          <img src="pokedex.png" alt={'Pokedex'} height={65} width={70} />
        }
        sx={{
          backgroundColor: 'transparent',
          boxShadow: 'none',
          ':hover': { bgcolor: 'lightgray' },
          height: 65,
          width: 70,
          border: 1,
          color: 'gray',
          marginRight: 1,
        }}
        onClick={() => setOpen(true)}
      />
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Card
          sx={{
            paddingTop: 3,
            marginLeft: 1,
            borderColor: 'white',
            border: 1,
            width: '60%',
            height: '80%',
          }}
          style={{
            height: 'fit-content',
          }}
        >
          <CardMedia
            component="img"
            sx={{ height: 80, objectFit: 'contain' }}
            image={'./pokedex.png'}
            title={'Stats'}
          />
          {shiny ? getShinyContent() : getNormalContent()}
        </Card>
      </Modal>
    </>
  )
}

export default Collection
