import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Modal,
  Typography,
} from '@mui/material'
import { useContext, useState } from 'react'
import { AuthContext } from '../context/auth'

const Stats = () => {
  const { userData } = useContext(AuthContext)
  const { stats } = userData

  const [open, setOpen] = useState<boolean>(false)

  return (
    <>
      <Button
        variant="contained"
        startIcon={
          <img
            src="pc.png"
            alt={'PC from Pokemon Center'}
            height={62}
            width={30}
          />
        }
        sx={{
          backgroundColor: 'transparent',
          boxShadow: 'none',
          ':hover': { bgcolor: 'lightgray' },
          height: 65,
          width: 70,
          border: 1,
          color: 'gray',
        }}
        onClick={() => setOpen(true)}
      />
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Card
          sx={{
            paddingTop: 3,
            marginLeft: 1,
            borderColor: 'white',
            border: 1,
            width: '60%',
            height: '80%',
          }}
        >
          <CardMedia
            component="img"
            sx={{ height: 80, objectFit: 'contain' }}
            image={'./pc.png'}
            title={'Stats'}
          />
          <CardContent>
            <Typography
              gutterBottom
              variant="h4"
              component="div"
              textAlign={'center'}
            >
              Stats
            </Typography>
            <Grid container width={'100%'} rowSpacing={2}>
              <Grid item width={'100%'}>
                <b>Eggs Bought: </b>
                {`${Object.entries(stats.eggsBought)
                  .sort((a, b) => (a[1] < b[1] ? 1 : -1))
                  .map(([k, v]) => `${k} Egg x${v}`)
                  .join(', ')}`}
              </Grid>
              <Grid item width={'100%'}>
                <b>Eggs Hatched: </b>
                {`${Object.entries(stats.eggsHatched)
                  .sort((a, b) => (a[1] < b[1] ? 1 : -1))
                  .map(([k, v]) => `${k} Egg x${v}`)
                  .join(', ')}`}
              </Grid>
              <Grid item width={'100%'}>
                <b>Top 10 Most Hatched: </b>
                {`${Object.entries(userData.stats.hatches)
                  .sort((a, b) => (a[1].count < b[1].count ? 1 : -1))
                  .slice(0, 10)
                  .map((h) => `${h[1].name} (${h[1].count})`)
                  .join(', ')}`}
              </Grid>
              <Grid item width={'100%'}>
                <b>Top 10 Most Shinies Hatched: </b>
                {`${Object.entries(userData.stats.hatches)
                  .filter(([, data]) => data.shiny > 0)
                  .sort((a, b) => (a[1].shiny ?? 0 < b[1].shiny ?? 0 ? 1 : -1))
                  .slice(0, 10)
                  .map((h) => `${h[1].name} (${h[1].shiny})`)
                  .join(', ')}`}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Modal>
    </>
  )
}

export default Stats
