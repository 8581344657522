import { getApiAccessInfo } from '../accessInfo'
import { sendRequest } from '../axiosInstance'

export const refreshAccessToken = async () => {
  const { refreshToken, username, displayUsername } = getApiAccessInfo()
  const response = await sendRequest('/auth/refresh', 'POST', {
    token: refreshToken,
    username,
  })
  const { accessToken } = response

  const store = {
    accessToken,
    refreshToken,
    username,
    displayUsername,
  }
  localStorage.setItem('userInfo', JSON.stringify(store))

  return store
}
