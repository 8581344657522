import { Card, CardContent, CardMedia, Modal, Typography } from '@mui/material'
import { Egg } from '../../types/Egg'

const HatchCard = ({
  egg,
  data,
  open,
  close,
}: {
  egg: Egg
  data: { image: string; name: string }
  open: boolean
  close: (reason: string) => void
}) => {
  const createName = (name: string) => {
    if (egg.shiny) return `⭐${name}⭐`
    else return name
  }
  return (
    <Modal
      open={open}
      onClose={(_, reason) => close(reason)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Card
        sx={{
          paddingTop: 3,
          marginLeft: 1,
          borderColor: 'white',
          border: 1,
          width: '60%',
        }}
        style={{
          height: 'fit-content',
        }}
      >
        <CardMedia
          component="img"
          sx={{ height: 250, objectFit: 'contain' }}
          image={data.image}
          title={egg.pokemon.name}
        />
        <CardContent>
          <Typography
            gutterBottom
            variant="h3"
            component="div"
            textAlign={'center'}
          >
            {createName(data.name)}
          </Typography>
        </CardContent>
      </Card>
    </Modal>
  )
}

export default HatchCard
