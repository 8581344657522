import { Dispatch, SetStateAction, createContext } from 'react'
import { Egg } from '../types/Egg'

export type HatchContextType = {
  hatched: Egg | null
  showSnack: boolean
  hatching: boolean
  fastHatch: boolean
  setHatched: Dispatch<SetStateAction<Egg | null>>
  setShowSnack: Dispatch<SetStateAction<boolean>>
  setHatching: Dispatch<SetStateAction<boolean>>
  handleFastHatch: (state: boolean) => void
}

export const HatchContext = createContext<HatchContextType>({
  hatched: null,
  showSnack: false,
  hatching: false,
  fastHatch: false,
  setHatched: () => {},
  setShowSnack: () => {},
  setHatching: () => {},
  handleFastHatch: (state: boolean) => {},
})
