import { Outlet } from 'react-router-dom'
import { Navigate } from 'react-router-dom'
import { useContext } from 'react'
import { AuthContext } from '../context/auth'

const DefaultLayout = () => {
  const { userInfo } = useContext(AuthContext)
  if (userInfo?.username) {
    return <Navigate replace to={'/'} />
  }

  return (
    <>
      <Outlet />
    </>
  )
}

export default DefaultLayout
