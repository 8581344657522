import { Grid } from '@mui/material'
import EggCard from './cards/Egg'
import React, { useContext } from 'react'
import { AuthContext } from '../context/auth'

const EggCards = () => {
  const { userData } = useContext(AuthContext)

  return (
    <Grid container columns={5} width="100%">
      {(userData?.eggs || []).map((egg) => (
        <React.Fragment key={egg.id}>
          <EggCard egg={egg} />
        </React.Fragment>
      ))}
    </Grid>
  )
}

export default EggCards
