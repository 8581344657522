import { AxiosError } from 'axios'
import { sendRequest } from '../axiosInstance'
import { BuyEggs } from '../../types/Egg'

export const availableEggs = async (): Promise<BuyEggs> => {
  const response = await sendRequest(`/egg/available`, 'GET')
  if (response instanceof AxiosError) {
    return response.response?.data.message
  }
  return response
}
