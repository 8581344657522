export const getApiAccessInfo = () => {
  const data = JSON.parse(localStorage.getItem('userInfo') as string)
  if (!data) return {}
  return {
    accessToken: data.accessToken,
    refreshToken: data.refreshToken,
    username: data.username,
    displayUsername: data.displayUsername,
  }
}
