import { Dispatch, SetStateAction, createContext } from 'react'
import { DEFAULT_USER_DATA, UserBasicInfo, UserData } from '../types/User'
import { UpgradeBuilding } from '../types/Building'
import { BuyEggs } from '../types/Egg'

export type AuthContextType = {
  userInfo: UserBasicInfo
  userData: UserData
  availableUpgrades: {
    upcoming: UpgradeBuilding[]
    upgrades: UpgradeBuilding[]
  }
  availableEggs: BuyEggs
  setUserData: Dispatch<SetStateAction<UserData>>
  setUserInfo: Dispatch<SetStateAction<UserBasicInfo>>
  setAvailableUpgrades: Dispatch<
    SetStateAction<{ upcoming: UpgradeBuilding[]; upgrades: UpgradeBuilding[] }>
  >
  setAvailableEggs: Dispatch<SetStateAction<BuyEggs>>
}

export const AuthContext = createContext<AuthContextType>({
  userInfo: JSON.parse(localStorage.getItem('userInfo') as string),
  userData: DEFAULT_USER_DATA,
  availableUpgrades: { upgrades: [], upcoming: [] },
  availableEggs: {},
  setUserData: () => {},
  setUserInfo: () => {},
  setAvailableUpgrades: () => {},
  setAvailableEggs: () => {},
})
