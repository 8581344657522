import { useState } from 'react'
import { Routes, Route } from 'react-router-dom'
import Login from './pages/Login'
import Register from './pages/Register'
import DefaultLayout from './layouts/DefaultLayout'
import ProtectedLayout from './layouts/ProtectedLayout'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { AuthContext } from './context/auth'
import { DEFAULT_USER_DATA, UserBasicInfo, UserData } from './types/User'
import { UpgradeBuilding } from './types/Building'
import { HatchContext } from './context/hatch'
import { BuyEggs, Egg } from './types/Egg'
import Home from './pages/Home'

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  typography: {
    fontFamily: 'monospace',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        startIcon: {
          margin: '0',
        },
      },
    },
  },
})

function tryParseFromLocalStorage(target: string) {
  const localItem = localStorage.getItem(target)
  if (!localItem) return null
  try {
    return JSON.parse(localItem)
  } catch (err) {
    return null
  }
}

function App() {
  const localUserString = tryParseFromLocalStorage('userInfo')
  const localConfig = tryParseFromLocalStorage('config')

  const [userData, setUserData] = useState<UserData>(DEFAULT_USER_DATA)
  const [userInfo, setUserInfo] = useState<UserBasicInfo>(localUserString ?? {})
  const [availableUpgrades, setAvailableUpgrades] = useState<{
    upcoming: UpgradeBuilding[]
    upgrades: UpgradeBuilding[]
  }>({ upcoming: [], upgrades: [] })
  const [showSnack, setShowSnack] = useState<boolean>(false)
  const [availableEggs, setAvailableEggs] = useState<BuyEggs>({})

  const [hatched, setHatched] = useState<Egg | null>(null)
  const [hatching, setHatching] = useState<boolean>(false)

  const [fastHatch, setFastHatch] = useState<boolean>(
    localConfig ? localConfig.fastHatch : false
  )
  const handleFastHatch = (state: boolean) => {
    localStorage.setItem('config', JSON.stringify({ fastHatch: state }))
    setFastHatch(state)
  }

  return (
    <AuthContext.Provider
      value={{
        userData,
        setUserData,
        userInfo,
        setUserInfo,
        availableUpgrades,
        setAvailableUpgrades,
        availableEggs,
        setAvailableEggs,
      }}
    >
      <HatchContext.Provider
        value={{
          hatched,
          setHatched,
          showSnack,
          setShowSnack,
          hatching,
          setHatching,
          fastHatch,
          handleFastHatch,
        }}
      >
        <ThemeProvider theme={darkTheme}>
          <Routes>
            <Route element={<DefaultLayout />}>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
            </Route>

            <Route element={<ProtectedLayout />}>
              <Route path="/" element={<Home />} />
            </Route>
          </Routes>
        </ThemeProvider>
      </HatchContext.Provider>
    </AuthContext.Provider>
  )
}

export default App
