import { useContext, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Switch,
  Typography,
} from '@mui/material'
import EggCards from '../components/EggCards'
import { AuthContext } from '../context/auth'
import { getUser } from '../api/getUser'
import { logout } from '../api/auth/logout'
import { availableBuildings } from '../api/building/available'
import Stats from '../components/Stats'
import HatchSnack from '../components/HatchSnack'
import Collection from '../components/Collection'
import Buildings from '../components/Buildings'
import Incubator from '../components/Incubator'
import { availableEggs } from '../api/egg/available'
import { HatchContext } from '../context/hatch'

const Home = () => {
  const { userInfo, setUserData, setAvailableUpgrades, setAvailableEggs } =
    useContext(AuthContext)
  const { handleFastHatch, fastHatch } = useContext(HatchContext)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const get = async () => {
      setUserData(await getUser())
      setAvailableUpgrades(await availableBuildings())
      setAvailableEggs(await availableEggs())
      setLoading(false)
    }
    if (userInfo) {
      get()
    }
  }, [userInfo, setUserData, setAvailableUpgrades, setAvailableEggs])

  const handleLogout = async () => {
    try {
      logout()
      window.location.reload()
    } catch (e) {
      console.error(e)
    }
  }
  return (
    <Container maxWidth="lg">
      <CssBaseline />
      <HatchSnack />
      <Box
        sx={{
          mt: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img
          style={{ height: '120px', maxWidth: 'xs' }}
          src="/pogegg.png"
          className="center w-full bg-cover object-cover"
          alt="PogEgg Logo"
        />
        {loading ? (
          <Typography variant="h5" sx={{ marginBottom: 2, marginTop: 1 }}>
            Gathering data...
          </Typography>
        ) : (
          <>
            <Typography
              variant="h5"
              sx={{ marginBottom: 2, marginTop: 1 }}
            >{`${userInfo?.displayUsername}'s Nursery`}</Typography>
            <Grid item>
              <Incubator />
              <Buildings />
              <Collection />
              <Stats />
            </Grid>
            <Grid item marginTop={1}>
              Fast Hatch{' '}
              <Switch
                aria-label="Fast Hatch"
                checked={fastHatch}
                onChange={() => handleFastHatch(!fastHatch)}
              />
            </Grid>
            <Box sx={{ mt: 2, width: '100%' }}>
              <Grid container justifyContent={'center'}>
                <Box width={'100%'}>
                  <EggCards />
                </Box>

                <Grid item>
                  <Button
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={handleLogout}
                  >
                    Logout
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </Box>
    </Container>
  )
}

export default Home
