import { AxiosError } from 'axios'
import { NewUser, UserBasicInfo } from '../../types/User'
import { sendRequest } from '../axiosInstance'

export const register = async (
  data: NewUser
): Promise<string | UserBasicInfo> => {
  const response = await sendRequest('/auth/signup', 'POST', data)
  if (response instanceof AxiosError) {
    return response.response?.data.message
  }
  const { accessToken, refreshToken, user } = response
  const { username, displayUsername } = user

  const store = {
    username: username,
    displayUsername,
    accessToken,
    refreshToken,
  }

  return store
}
