import { AxiosError } from 'axios'
import { sendRequest } from '../axiosInstance'
import { UserData } from '../../types/User'

export const buyBuilding = async ({
  building,
  tier,
}: {
  building: string
  tier: number
}): Promise<string | UserData> => {
  const response = await sendRequest(`/building/buy`, 'POST', {
    type: building,
    tier: tier,
  })
  if (response instanceof AxiosError) {
    return response.response?.data.message
  }
  return response
}
