import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from '@mui/material'
import { Building } from '../../types/Building'
import { buyBuilding } from '../../api/building/buy'
import { useContext } from 'react'
import { AuthContext } from '../../context/auth'
import { availableBuildings } from '../../api/building/available'

const BuildingCard = ({ building }: { building: Building }) => {
  const { availableUpgrades, setAvailableUpgrades, setUserData } =
    useContext(AuthContext)
  const handleBuyBuilding = async (type: string, tier: number) => {
    try {
      const data = await buyBuilding({
        building: type,
        tier,
      })
      if (typeof data === 'string') {
        return
      }
      setAvailableUpgrades(await availableBuildings())
      setUserData(data)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Grid item sx={{ width: '100%', height: '100%' }}>
      <Card
        sx={{
          paddingTop: 1,
          borderColor: 'white',
          border: 1,
          height: '100%',
        }}
      >
        <CardContent>
          <Typography
            variant="h5"
            component="div"
            textAlign={'center'}
            marginBottom={2}
          >
            {building.type}
          </Typography>
          <Typography component="div" textAlign={'center'}>
            <Grid container justifyContent={'center'}>
              <Grid item xs={12}>{`Tier: ${building.tier}`}</Grid>
              <Grid item xs={12}>{`Effect: ${building.effect}`}</Grid>
            </Grid>
          </Typography>
        </CardContent>

        <Typography variant="h5" component="div" textAlign={'center'}>
          <CardActions>
            <Grid container justifyContent={'center'}>
              {availableUpgrades.upgrades.find(
                (u) => u.type === building.type
              ) ? (
                <Button
                  variant="contained"
                  sx={{ width: '100%' }}
                  onClick={() =>
                    handleBuyBuilding(building.type, building.tier + 1)
                  }
                >
                  Upgrade!
                </Button>
              ) : (
                <Box sx={{ width: '100%' }}>
                  {availableUpgrades.upcoming.find(
                    (u) => u.type === building.type
                  ) ? (
                    `Tier ${building.tier + 1} available at ${
                      availableUpgrades.upcoming.find(
                        (u) => u.type === building.type
                      )!.buildThreshold
                    }/151`
                  ) : (
                    <b>Fully Upgraded</b>
                  )}
                </Box>
              )}
            </Grid>
          </CardActions>
        </Typography>
      </Card>
    </Grid>
  )
}

export default BuildingCard
