import { AxiosError } from 'axios'
import { sendRequest } from '../axiosInstance'
import { UpgradeBuilding } from '../../types/Building'

export const availableBuildings = async (): Promise<{
  upcoming: UpgradeBuilding[]
  upgrades: UpgradeBuilding[]
}> => {
  const response = await sendRequest(`/building/available`, 'GET')
  if (response instanceof AxiosError) {
    return response.response?.data.message
  }
  return response
}
