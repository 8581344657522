import { AxiosError } from 'axios'
import { User, UserBasicInfo } from '../../types/User'
import { sendRequest } from '../axiosInstance'

export const login = async (data: User): Promise<string | UserBasicInfo> => {
  const response = await sendRequest('/auth/login', 'POST', data)
  if (response instanceof AxiosError) {
    return response.response?.data.message
  }
  const { accessToken, refreshToken, user } = response
  const { username, displayUsername } = user
  const store = {
    username: username,
    displayUsername: displayUsername,
    accessToken,
    refreshToken,
  }
  return store
}
