import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  List,
  ListItem,
  Modal,
  Switch,
  Typography,
} from '@mui/material'
import { useContext, useState } from 'react'
import { AuthContext } from '../context/auth'
import { buyEgg } from '../api/egg/buy'
import { EggType } from '../types/Egg'

const Incubator = () => {
  const [open, setOpen] = useState<boolean>(false)
  const { availableEggs, userData, setUserData } = useContext(AuthContext)
  const [buying, setBuying] = useState<boolean>(false)
  const [buyAll, setBuyAll] = useState<boolean>(false)

  const handleBuyEgg = async (type: EggType) => {
    try {
      setBuying(true)
      const data = await buyEgg(type, buyAll)
      setUserData({ ...userData, ...data })
    } catch (e) {
      console.error(e)
    } finally {
      setBuying(false)
    }
  }

  const handleButtonName = (egg: EggType, cost: number) => {
    const output = `${egg.padEnd(9, '\u00a0')} - ${cost
      .toString()
      .padStart(4, '\u00a0')}`
    return output
  }

  return (
    <>
      <Button
        variant="contained"
        startIcon={
          <img src="incubator.png" alt={'Incubator'} height={60} width={45} />
        }
        sx={{
          backgroundColor: 'transparent',
          boxShadow: 'none',
          ':hover': { bgcolor: 'lightgray' },
          height: 65,
          width: 70,
          border: 1,
          color: 'gray',
          marginRight: 1,
        }}
        onClick={() => setOpen(true)}
      />
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Card
          sx={{
            paddingTop: 3,
            borderColor: 'white',
            border: 1,
            width: '60%',
            height: '80%',
          }}
        >
          <CardMedia
            component="img"
            sx={{ height: 80, objectFit: 'contain' }}
            image={'./incubator.png'}
            title={'Incubator'}
          />
          <CardContent sx={{ height: '90%' }}>
            <Grid
              container
              columns={5}
              textAlign="center"
              justifyContent="center"
            >
              <Grid item xs="auto">
                <Typography gutterBottom variant="h4" component="div">
                  Incubator
                </Typography>
              </Grid>
              <Grid item xs="auto">
                <CardMedia
                  component="img"
                  image={'coin.png'}
                  title="Coin"
                  alt="Coin"
                  sx={{ height: 30, objectFit: 'contain' }}
                />
                {userData.currency.coins}
              </Grid>
              <Grid item xs={5}>
                Buy All{' '}
                <Switch
                  aria-label="Fast Hatch"
                  checked={buyAll}
                  onChange={() => setBuyAll(!buyAll)}
                />
              </Grid>
              {(userData?.eggs || []).length >= userData.nurseryCapacity && (
                <Typography color={'red'}>Nursery Full</Typography>
              )}
              {window.innerWidth < 500 ? (
                <List sx={{ width: '100%', height: '55vh', overflow: 'auto' }}>
                  {(
                    Object.entries(availableEggs || {}) as [EggType, number][]
                  ).map(([e, val]) => (
                    <ListItem
                      dense={true}
                      key={e}
                      sx={{ alignItems: 'center', justifyContent: 'center' }}
                    >
                      <Button
                        sx={{ border: 1, maxHeight: 50 }}
                        disabled={
                          (userData?.eggs || []).length >=
                            userData.nurseryCapacity ||
                          buying ||
                          val > userData.currency.coins
                        }
                        onClick={() => handleBuyEgg(e)}
                      >
                        {handleButtonName(e, val)}
                      </Button>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Grid
                  container
                  width={'100%'}
                  marginTop={1}
                  justifyContent={'center'}
                  columnSpacing="3px"
                  rowSpacing="3px"
                >
                  {(
                    Object.entries(availableEggs || {}) as [EggType, number][]
                  ).map(([e, val]) => (
                    <Grid item key={e}>
                      <Button
                        sx={{ border: 1 }}
                        disabled={
                          (userData?.eggs || []).length >=
                            userData.nurseryCapacity ||
                          buying ||
                          val > userData.currency.coins
                        }
                        onClick={() => handleBuyEgg(e)}
                      >
                        {handleButtonName(e, val)}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Modal>
    </>
  )
}

export default Incubator
