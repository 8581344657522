import axios, { AxiosError } from 'axios'
import { BACKEND_BASE_URL } from '../contants'
import { refreshAccessToken } from './auth/refreshToken'
import { logout } from './auth/logout'
import { getApiAccessInfo } from './accessInfo'
import { JwtPayload, jwtDecode } from 'jwt-decode'

// Create an Axios instance with default options
const axiosInstance = axios.create({
  baseURL: BACKEND_BASE_URL,
})

type AuthPayload = JwtPayload & { username: string }
const isTokenExpired = (token: string) => {
  const decoded: AuthPayload = jwtDecode(token)
  return (decoded.exp ?? 0) * 1000 < Date.now()
}
export async function sendRequest(
  url: string,
  method: 'GET' | 'POST',
  data?: any
) {
  try {
    let accessToken = '',
      username = '',
      refreshToken = ''

    if (!url.includes('/auth')) {
      ;({ accessToken, username, refreshToken } = getApiAccessInfo())
      if (isTokenExpired(accessToken)) {
        if (isTokenExpired(refreshToken)) {
          logout()
          window.location.reload()
        }
        await refreshAccessToken()
        ;({ accessToken, username } = getApiAccessInfo())
      }
    }

    const res = await axiosInstance.request({
      method,
      url,
      data,
      headers: {
        ...(username && accessToken && { username, token: accessToken }),
      },
    })
    return res.data
  } catch (err) {
    console.error(err)
    if (err instanceof AxiosError) {
      return err
    }
  }
}

export default axiosInstance
