import { Building } from './Building'
import { Currency } from './Currency'
import { Egg } from './Egg'
import { Mon } from './Mon'
import { Stats } from './Stats'

export type User = {
  username: string
  password: string
}

export type NewUser = User & {
  email: string
}

export type UserBasicInfo = {
  username: string
  displayUsername: string
  accessToken: string
  refreshToken: string
}

export const DEFAULT_USER_DATA: UserData = {
  eggs: [],
  buildings: [],
  collection: [],
  stats: {
    eggsBought: {},
    eggsHatched: {},
    hatches: {},
  },
  currency: { coins: 0 },
  nurseryCapacity: 6,
}
export type UserData = {
  eggs: Egg[]
  buildings: Building[]
  collection: Mon[]
  stats: Stats
  currency: Currency
  nurseryCapacity: number
}
