import { useContext } from 'react'
import { Outlet } from 'react-router-dom'
import { Navigate } from 'react-router-dom'
import { AuthContext } from '../context/auth'

const ProtectedLayout = () => {
  const { userInfo } = useContext(AuthContext)
  if (!userInfo?.username || !userInfo) {
    return <Navigate replace to={'/login'} />
  }
  return (
    <>
      <Outlet />
    </>
  )
}

export default ProtectedLayout
