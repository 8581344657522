import {
  Button,
  Card,
  CardActions,
  CardMedia,
  Grid,
  Typography,
} from '@mui/material'
import { Egg, MysteryEgg } from '../../types/Egg'
import { hatchEgg } from '../../api/egg/hatch'
import { useContext, useState } from 'react'
import { AuthContext } from '../../context/auth'
import { Mon } from '../../types/Mon'
import HatchCard from './Hatch'
import { Stats } from '../../types/Stats'
import { availableBuildings } from '../../api/building/available'
import { HatchContext } from '../../context/hatch'
import { Currency } from '../../types/Currency'
import { CountdownTimer } from '../Countdown'

interface LocalData {
  collection: Mon[]
  eggs: Egg[]
  stats: Stats
  currency: Currency
}

function timeout(delay: number) {
  return new Promise((res) => setTimeout(res, delay))
}
const EggCard = ({ egg }: { egg: MysteryEgg }) => {
  const { userData, setUserData, setAvailableUpgrades } =
    useContext(AuthContext)
  const {
    hatched,
    setHatched,
    setShowSnack,
    hatching,
    setHatching,
    fastHatch,
  } = useContext(HatchContext)

  const [localData, setLocalData] = useState<LocalData>()
  const [open, setOpen] = useState<boolean>(false)

  const [hatchData, setHatchData] = useState<{ image: string; name: string }>({
    image: '/hatch.gif',
    name: '???',
  })
  const handleClose = async (reason: string) => {
    if (reason === 'backdropClick' && hatching) return
    setUserData({
      ...userData,
      collection: localData!.collection,
      eggs: localData!.eggs,
      stats: localData!.stats,
      currency: localData!.currency,
    })
    setAvailableUpgrades(await availableBuildings())
  }
  const handleHatch = async (id: string) => {
    try {
      setHatching(true)
      setShowSnack(false)
      const { hatched, collection, eggs, stats, currency } = await hatchEgg(id)
      setLocalData({ collection, eggs, stats, currency })
      setHatched(hatched)
      const url = `https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/versions/generation-ii/crystal/transparent/${
        hatched.shiny ? 'shiny/' : ''
      }${hatched!.pokemon.id}.png`
      if (fastHatch) {
        setHatchData({
          image: url,
          name: hatched!.pokemon.name,
        })
        setOpen(true)
      } else {
        setOpen(true)
        await timeout(4000)
        setHatchData({
          image: url,
          name: hatched!.pokemon.name,
        })
      }

      setShowSnack(true)
      setHatching(false)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Grid item sx={{ width: '20%' }}>
      <Card
        sx={{
          paddingTop: 1,
          borderColor: 'white',
          border: 1,
          height: '100%',
          borderRadius: 5,
        }}
      >
        <CardMedia
          component="img"
          sx={{ height: 90, objectFit: 'contain' }}
          image={`/egg-${egg.type}.png`}
          title={`${egg.type} Egg`}
        />

        <Typography variant="h6" component="div" textAlign={'center'}>
          <CardActions>
            <Grid container justifyContent={'center'} minHeight={40}>
              <CountdownTimer
                targetDate={
                  new Date(egg.incubatedAt).getTime() +
                  (egg.secondsToHatch + 2) * 1000
                }
              >
                <Button
                  variant="contained"
                  key={egg.id}
                  onClick={() => handleHatch(egg.id)}
                  color="success"
                  disabled={hatching}
                >
                  HATCH!
                </Button>
              </CountdownTimer>
            </Grid>
          </CardActions>
        </Typography>
      </Card>
      {hatched && (
        <HatchCard
          open={open}
          close={handleClose}
          egg={hatched}
          data={hatchData}
        />
      )}
    </Grid>
  )
}

export default EggCard
