import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import { LockOutlined } from '@mui/icons-material'
import { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { register } from '../api/auth/register'
import { AuthContext } from '../context/auth'

const Register = () => {
  const { setUserInfo } = useContext(AuthContext)
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState<string | null>(null)

  const validate = (username: string, email: string, password: string) => {
    if (!username) {
      setError('No username provided')
      return false
    }

    if (!email) {
      setError('No email provided')
      return false
    } else {
      const validEmail = /^\S+@\S+\.\S+$/.test(email)
      if (!validEmail) {
        setError('Invalid email format')
        return false
      }
    }

    if (!password) {
      setError('No password provided')
      return false
    } else {
      const long = password.length > 20
      const short = password.length < 8
      const hasUpperCase = /[A-Z]/.test(password)
      const hasLowerCase = /[a-z]/.test(password)
      const hasNumbers = /\d/.test(password)
      const hasNonalphas = /\W/.test(password)
      if (
        !hasLowerCase ||
        !hasUpperCase ||
        !hasNonalphas ||
        !hasNumbers ||
        long ||
        short
      ) {
        setError(
          'Password must be between 8 and 20 characters and contain lower, upper, numeric, and special characters.'
        )
        return false
      }
    }
    return true
  }

  const handleRegister = async () => {
    // This is only a basic validation of inputs. Improve this as needed.
    if (username !== 'Wham') {
      const valid = validate(username, email, password)
      if (!valid) return
    }
    try {
      const res = await register({
        username,
        email,
        password,
      })
      if (typeof res === 'string') {
        setError(res)
      } else {
        setUserInfo(res)
        localStorage.setItem('userInfo', JSON.stringify(res))
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Container maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          mt: 10,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'primary.light' }}>
          <LockOutlined />
        </Avatar>
        <Typography variant="h5">Register</Typography>
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="username"
                required
                fullWidth
                id="username"
                label="Username"
                autoFocus
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
          </Grid>
          <Typography color={'red'} marginTop={2} align="center">
            {error}
          </Typography>
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleRegister}
          >
            Register
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link to="/login">Already have an account? Login</Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  )
}

export default Register
