import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  List,
  ListItem,
  Modal,
  Typography,
} from '@mui/material'
import { useContext, useState } from 'react'
import { AuthContext } from '../context/auth'
import BuildingCard from './cards/Building'

const Buildings = () => {
  const { userData } = useContext(AuthContext)

  const [open, setOpen] = useState<boolean>(false)

  return (
    <>
      <Button
        variant="contained"
        startIcon={
          <img
            src="pokecenter.png"
            alt={'Pokemon Center'}
            height={64}
            width={64}
          />
        }
        sx={{
          backgroundColor: 'transparent',
          boxShadow: 'none',
          ':hover': { bgcolor: 'lightgray' },
          height: 65,
          width: 70,
          border: 1,
          color: 'gray',
          marginRight: 1,
        }}
        onClick={() => setOpen(true)}
      />

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Card
          sx={{
            paddingTop: 3,
            marginLeft: 1,
            borderColor: 'white',
            border: 1,
            width: '60%',
            height: '80%',
          }}
        >
          <CardMedia
            component="img"
            sx={{ height: 80, objectFit: 'contain' }}
            image={'./pokecenter.png'}
            title={'Buildings'}
          />
          <CardContent>
            <Grid container textAlign="center" justifyContent="center">
              <Grid item xs="auto">
                <Typography gutterBottom variant="h4" component="div">
                  Buildings
                </Typography>
              </Grid>

              {window.innerWidth < 500 ? (
                <List sx={{ width: '100%', height: '55vh', overflow: 'auto' }}>
                  {(userData?.buildings || []).map((b) => (
                    <ListItem
                      dense={true}
                      key={b.type}
                      sx={{ alignItems: 'center', justifyContent: 'center' }}
                    >
                      <BuildingCard building={b} />
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Grid
                  container
                  width={'100%'}
                  columns={2}
                  justifyContent={'left'}
                  columnSpacing="3px"
                  rowSpacing="3px"
                >
                  {(userData?.buildings || []).map((b) => (
                    <Grid item xs={1} key={b.type}>
                      <BuildingCard building={b} />
                    </Grid>
                  ))}
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Modal>
    </>
  )
}

export default Buildings
