import { useEffect, useState } from 'react'

const useCountdown = (targetDate: number) => {
  const countDownDate = new Date(targetDate).getTime()

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  )

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime())
    }, 1000)

    return () => clearInterval(interval)
  }, [countDownDate])

  return getReturnValues(countDown)
}

const getReturnValues = (countDown: number) => {
  // calculate time left
  const minutes = Math.floor(countDown / (1000 * 60))
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000)

  return [minutes, seconds]
}

export const CountdownTimer = ({
  targetDate,
  children,
}: {
  targetDate: number
  children: React.ReactNode
}) => {
  const [minutes, seconds] = useCountdown(targetDate)

  if (minutes + seconds <= 0) {
    return <div>{children}</div>
  } else {
    return (
      <div>
        {minutes.toString().padStart(2, '0')}:
        {seconds.toString().padStart(2, '0')}
      </div>
    )
  }
}
