import { IconButton, Snackbar } from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import { HatchContext } from '../context/hatch'
import { useContext } from 'react'

const HatchSnack = () => {
  const { hatched, showSnack, setShowSnack } = useContext(HatchContext)

  return (
    <Snackbar
      open={showSnack}
      message={`You hatched a${hatched?.shiny ? ' ⭐SHINY⭐' : ''} ${
        hatched?.pokemon.name
      }!`}
      anchorOrigin={{
        vertical: window.innerWidth < 500 ? 'top' : 'bottom',
        horizontal: window.innerWidth < 500 ? 'center' : 'left',
      }}
      sx={{ width: '60%' }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => setShowSnack(false)}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    ></Snackbar>
  )
}

export default HatchSnack
